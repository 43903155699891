import './src/styles/globals.css'
import './src/styles/components/background.css'
import './src/styles/components/carousel.css'
import './src/styles/components/heading.css'
import './src/styles/components/hero.css'
import './src/styles/components/icon-badge.css'
import './src/styles/components/list.css'
import './src/styles/components/steps.css'
import './src/styles/components/nav.css'
import './src/styles/components/range.css'
import './src/styles/components/spotlight.css'
import './src/styles/components/tabs.css'
import './src/styles/components/logos.css'
import './src/styles/components/quote.css'
import './src/styles/libs/react-rotating-text.css'

import '@fontsource/poppins'
import '@fontsource/poppins/500.css'
import '@fontsource/poppins/600.css'
import '@fontsource/poppins/700.css'


export const wrapRootElement = ({ element }) => {
  return element;
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (!window?.trackingScripts?.enabled) {
    return
  }

  if (!prevLocation) {
    // this is handled in the init function
    return;
  }

  const trackPageView = () => {
    const path = location ? location.pathname + location.search + location.hash : undefined
  
    // Gtag
    if (window.gtag && typeof window.gtag === 'function') {
      window.gtag(`event`, `page_view`, { page_path: path })
    }
    
    // Hubspot
    if (window._hsp && typeof window._hsp === 'object') {
      window._hsp.push(['setPath', path])
      window._hsp.push(['trackPageView'])
    }

    // Segment
    if (window.analytics && typeof window.analytics === 'object') {
      window.analytics.page();
    }
  }

  if (typeof window.requestAnimationFrame === 'function') {
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(trackPageView)
    })
  } else {
    // simulate 2 requestAnimationFrame calls
    setTimeout(trackPageView, 32)
  }
}